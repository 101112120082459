import React from "react";
import { withTranslation } from "react-i18next";

const DetailsComponent = (props) => {
  const { details, t } = props;

  return (
    <div className="containerDetails">
      <p className="titleDetails">{t("PRODUCT DETAILS", "PRODUCT DETAILS")}</p>

      <ul>
        {details.map((detail, index) => (
          <li key={index}>{Boolean(detail.text_en) ? detail.text_en : detail.text}</li>
        ))}
      </ul>
    </div>
  );
};

export default withTranslation()(DetailsComponent);
