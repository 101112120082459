import React from 'react';
import "../style/BrandStyleStyles.scss"

const BrandStyleComponent = props => {

    return (
        <div className="brandStyle">
            { props.children }
        </div>
    );
};

export default BrandStyleComponent;
